@tailwind base;
@tailwind components;
@tailwind utilities;
@import "react-toastify/dist/ReactToastify.css";
@import url('https://fonts.googleapis.com/css2?family=Sarabun&display=swap');

* {
  font-family: 'Sarabun', sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../public/images/landingpageV4/background-desktop.png");
  /* background-image: url("../public/images/landingpage/wallpaper_mobile.png"); */
  /* background-image: url("../public/images/landingpageV3/bg-pc.png"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media only screen and (min-width: 768px) {
  body{
    background-image: url("../public/images/landingpageV4/background-mobile.png");
    /* background-image: url("../public/images/landingpage/wallpaper_desktop.png"); */
    /* background-image: url("../public/images/landingpageV3/bg-pc.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed; */
  }
}


/* @media screen and (min-width: 375px) {
  .character {
    width: 76% !important;
  }
}

@media screen and (min-width: 412px) {
  .character {
    width: 70% !important;
  }
}

@media screen and (min-width: 1024px) {
  .img {
    width: 40% !important;
  }
  .character {
    width: 35% !important;
  }
}

@media screen and (min-width: 1440px) {
  .logo {
    left: 10% !important;
  }

  .banner {
    left: 18% !important;
    bottom: 19% !important;
    width: 60% !important;
  }

  .character {
    width: 30% !important;
  }

  .btn {
    bottom: 10% !important;
  }
}

@media screen and (min-width: 1920px) {
  .logo {
    left: 15% !important;
  }

  .banner {
    left: 22% !important;
    bottom: 16% !important;
    width: 55% !important;
  }

  .character {
    width: 25% !important;
    left: 57% !important;
  }

  .btn {
    bottom: 6% !important;
  }
}

@media screen and (min-width: 2000px) {
  .logo {
    left: 10% !important;
  }

  .banner {
    left: 23% !important;
    bottom: 15% !important;
    width: 60% !important;
  }

  .character {
    width: 25% !important;
    left: 60% !important;
  }

  .btn {
    bottom: 5% !important;
  }
}

@media screen and (min-width: 2560px) {
  .logo {
    left: 15% !important;
  }

  .banner {
    left: 23% !important;
    bottom: 19% !important;
    width: 60% !important;
  }

  .character {
    width: 25% !important;
    left: 60% !important;
  }

  .btn {
    bottom: 10% !important;
  }
} */